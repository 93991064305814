// App.js

import React, { useEffect, useState } from 'react';
import TrainingMode from './components/TrainingMode/TrainingMode';
import TestMode from './components/TestMode/TestMode';
import './App.css';
import axios from 'axios';
import TagManager from 'react-gtm-module';

function App() {
  const [mode, setMode] = useState('training');  // Default to training mode
  const [category, setCategory] = useState(null); // No default category until data is loaded
  const [categories, setCategories] = useState([]); // Fetch categories dynamically

  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Initialize Google Tag Manager using key from .env file
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID, // Get GTM ID from .env
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  // Fetch categories from backend when component mounts
  useEffect(() => {
    axios.get(`${BASE_URL}/categories`)
      .then(response => {
        setCategories(response.data);
        setCategory(response.data[0]?.id);  // Set the first category as the default
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, [BASE_URL]);

  // Switch between modes (Training and Test)
  const handleModeSwitch = (selectedMode) => {
    setMode(selectedMode);
  };

  // Handle category selection
  const handleCategoryClick = (categoryId) => {
    setCategory(categoryId);
  };

  return (
    <div className="App">
      <header>
        <h1>GMAP buddy</h1>

        {/* Site Description */}
        <p className="site-description">
          練習問題に取り組み、GMAP試験の模擬試験を受けるお手伝いをします。<br></br>
          質問の内容は<a href="https://amzn.to/3UbO00h" target="_blank" rel="noopener noreferrer">グロービスMBAマネジメント・ブック【改訂3版】</a>に基づいて作成しました。
        </p>

        <div className="mode-switcher">
          <button onClick={() => handleModeSwitch('training')}>Training Mode</button>
          <button onClick={() => handleModeSwitch('test')}>Test Mode</button>
        </div>
        <div className="category-selector">
          {categories.map(cat => (
            <button
              key={cat.id}
              className={`category-button ${category === cat.id ? 'active' : ''}`}
              onClick={() => handleCategoryClick(cat.id)}
            >
              {cat.name}
            </button>
          ))}
        </div>
      </header>

      <main>
        {mode === 'training' ? (
          <TrainingMode category={category} />
        ) : (
          <TestMode category={category} />
        )}
      </main>

      {/* Affiliate Links Section */}
      <section className="affiliate-links">
        <h2>おすすめ書籍</h2>
        <ul>
          <li><a href="https://amzn.to/3UbO00h" target="_blank" rel="noopener noreferrer"><img src="https://images-fe.ssl-images-amazon.com/images/G/09/associates/buttons/assocbutt_or_buy.png" alt="Amazon"></img>グロービスMBAマネジメント・ブック【改訂3版】</a></li>
          <li><a href="https://amzn.to/4dSBGJk" target="_blank" rel="noopener noreferrer"><img src="https://images-fe.ssl-images-amazon.com/images/G/09/associates/buttons/assocbutt_or_buy.png" alt="Amazon"></img>グロービスMBAマネジメント･ブック［改訂3版］ Kindle Edition</a></li>
          <li><a href="https://amzn.to/4dI1Zlu" target="_blank" rel="noopener noreferrer"><img src="https://images-fe.ssl-images-amazon.com/images/G/09/associates/buttons/assocbutt_or_buy.png" alt="Amazon"></img>グロービス経営大学院Amazonストア</a></li>
          <li><a href="https://amzn.to/3Ypz2GD" target="_blank" rel="noopener noreferrer"><img src="https://images-fe.ssl-images-amazon.com/images/G/09/associates/buttons/assocbutt_or_buy.png" alt="Amazon"></img>MBAに役に立つ書籍一覧</a></li>
        </ul>
      </section>

      {/* Footer */}
      <footer className="footer">
        <p>&copy; {new Date().getFullYear()} gmap.nanh.vn. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
