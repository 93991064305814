// TestMode.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './TestMode.css';

const TestMode = ({ category }) => {
  const [questions, setQuestions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [score, setScore] = useState(null);
  const [timeLeft, setTimeLeft] = useState(20 * 60); // 20 minutes in seconds

  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Fetch 20 random questions for the test
  useEffect(() => {
    axios.get(`${BASE_URL}/test/${category}`)
      .then(response => {
        setQuestions(response.data);
      })
      .catch(error => {
        console.error('Error fetching test questions:', error);
      });
  }, [category, BASE_URL]);

  // Countdown timer logic
  useEffect(() => {
    if (timeLeft > 0 && !submitted) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer); // Clear the interval on component unmount
    } else if (timeLeft === 0) {
      handleSubmit(); // Automatically submit when time runs out
    }
  }, [timeLeft, submitted]);

  // Format time for display (MM:SS)
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // Handle answer selection
  const handleAnswerClick = (questionId, answerText) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionId]: answerText, // Store selected answer as text
    });
  };

  // Submit the test and calculate the score
  const handleSubmit = () => {
    setSubmitted(true);

    let score = 0;
    questions.forEach(question => {
      if (selectedAnswers[question.question_id] === question.correct_answer) {
        score += 1; // Increment score if the selected answer matches the correct answer
      }
    });

    setScore(score);
  };

  return (
    <div>
      {!submitted ? (
        <div>
          <h1>Test Mode</h1>
          <div className="countdown-timer">
            残り時間: <span className="time">{formatTime(timeLeft)}</span>
          </div>
          {questions.map((question, index) => (
            <div key={index} className="question-block">
              <h2>{question.question}</h2>
              {question.answers.map((answer, answerIndex) => (
                <button
                  key={answerIndex}
                  className={`answer-button ${selectedAnswers[question.question_id] === answer ? 'selected' : ''}`}
                  onClick={() => handleAnswerClick(question.question_id, answer)}
                >
                  {answer}
                </button>
              ))}
            </div>
          ))}
          <button className="submit-button" onClick={handleSubmit}>提出</button>
        </div>
      ) : (
        <div>
          <h1>お疲れ様でした！</h1>
          {score !== null ? (
            <p>{questions.length}点中{score}点でした</p>
          ) : (
            <p>提出中...</p>
          )}
        </div>
      )}
    </div>
  );
};

export default TestMode;
