// TrainingMode.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './TrainingMode.css';  // Import the CSS file

const TrainingMode = ({ category }) => {
  const [question, setQuestion] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [showAppendix, setShowAppendix] = useState(false);  // State to control when to show appendix

  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Fetch a random question for training mode
  const fetchQuestion = () => {
    axios.get(`${BASE_URL}/training/${category}`)
      .then(response => {
        setQuestion(response.data);
        setCorrectAnswer(response.data.correct_answer);
        setSelectedAnswer(null); // Reset selected answer when new question is fetched
        setFeedback(null);        // Reset feedback for the new question
        setShowAppendix(false);   // Hide appendix when a new question is loaded
      })
      .catch(error => {
        console.error('Error fetching question:', error);
      });
  };

  // Load a question when the component mounts or category changes
  useEffect(() => {
    fetchQuestion();
  }, [category, BASE_URL]);

  // Handle answer selection
  const handleAnswerClick = (answerText) => {
    setSelectedAnswer(answerText);
    if (answerText === correctAnswer) {
      setFeedback('Correct!');
    } else {
      setFeedback('Incorrect.');
    }
    setShowAppendix(true);  // Show appendix after the answer is selected
  };

  // Handle Next button click
  const handleNextQuestion = () => {
    fetchQuestion(); // Fetch the next random question
  };

  if (!question) {
    return <div>Loading question...</div>;
  }

  return (
    <div>
      <h1>Training Mode</h1>
      <div className="question-block">
        <h2>{question.question}</h2>
        {question.answers.map((answer, index) => (
          <button
            key={index}
            className={`answer-button ${selectedAnswer === answer ? (answer === correctAnswer ? 'correct' : 'incorrect') : ''}`}
            onClick={() => handleAnswerClick(answer)}
          >
            {answer}
          </button>
        ))}
      </div>
      {feedback && <div className={`feedback ${feedback === 'Correct!' ? 'feedback-correct' : 'feedback-incorrect'}`}>{feedback}</div>}
      
      {/* Only show appendix after user selects an answer */}
      {showAppendix && question.appendix && (
        <p className="appendix">{question.appendix}</p>
      )}

      {/* Next Question Button */}
      <button className="next-button" onClick={handleNextQuestion}>Next</button>
    </div>
  );
};

export default TrainingMode;
